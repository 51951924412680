import React from "react";

export default function ContactPage() {
  return (
    <div className="conatct-container">
      <h2 style={{
        textAlign: "center",
        color: "blue",
        fontSize: "50px",
        padding: "20px",
      }}>MARZI PLUS</h2>
      <h2>Contact Us</h2>
      <p>Email:marziplus@gmail.com </p>
    </div>
  );
}
