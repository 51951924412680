import React from "react";

export default function About() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <h2 
        style={{
          textAlign: "center",
          color: "blue",
          fontSize: "50px",
          padding: "20px",
        }}
      >Marzi Plus Trading PLC</h2>
    </div>
  );
}
